
import { defineComponent, onMounted } from "vue";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import DashboardWidget from "@/components/widgets/lists/DashboardWidget.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { string } from "yup/lib/locale";

export default defineComponent({
  name: "dashboard",
  components: {
    TableWidget9,
    TableWidget5,
    ListWidget1,
    ListWidget2,
    ListWidget3,
    ListWidget5,
    ListWidget6,
    MixedWidget2,
    MixedWidget5,
    MixedWidget7,
    MixedWidget10,
    DashboardWidget,
  },
  data(): {
    Auth: any;
    List: Array<any>;
  } {
    return {
      Auth: {},
      List: [],
    };
  },
  methods: {
    async Init() {
      // this.Auth = JSON.parse(localStorage.getItem("Auth"));
      let str: any;
      str = localStorage.getItem("Auth");
      this.Auth = JSON.parse(str);
      switch (this.Auth.Role.Code) {
        case "root":
          this.List = [
            {
              Text: "工單進度",
              Link: "/system/Ht-Tr-parameter/List",
            },
            {
              Text: "機台儀表板",
              Link: "/system/ProductionLine/Machine",
            },
            {
              Text: "料貨號儀表板",
              Link: "/system/ProductionLine/Material",
            },
            {
              Text: "來料分析列表",
              Link: "/system/Ht-Tr-technology/Add",
            },
            {
              Text: "進料檢驗列表",
              Link: "/system/IQC/List",
            },
            {
              Text: "料貨號列表",
              Link: "/system/Modal/List",
            },
            {
              Text: "新增料貨號",
              Link: "/system/Modal/Add",
            },
          ];
          break;

        case "admin":
          this.List = [
            {
              Text: "工單進度",
              Link: "/system/Ht-Tr-parameter/List",
            },
            {
              Text: "機台儀表板",
              Link: "/system/ProductionLine/Machine",
            },
            {
              Text: "進料檢驗列表",
              Link: "/system/IQC/List",
            },
          ];
          break;
      }
      if (this.Auth.Name == "上翊") {
        this.List = [
          {
            Text: "工單進度",
            Link: "/system/Ht-Tr-parameter/List",
          },
          {
            Text: "機台儀表板",
            Link: "/system/ProductionLine/Machine",
          },
          {
            Text: "進料檢驗列表",
            Link: "/system/IQC/List",
          },
        ];
      }
    },
  },
  async created() {
    this.List = [];
    setTimeout(() => {
      this.Init();
    }, 500);
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("選單區");
    });
  },
});
