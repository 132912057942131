
import { defineComponent, ref } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-widget-6",
  components: {
    Dropdown3,
  },
  props: {
    title: String,
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      {
        type: "智慧排程",
        color: "warning",
        icon: "icons/duotune/abstract/abs027.svg",
        title: "累計訂單數",
        text: "數值",
        number: "25",
      },
      {
        type: "智慧排程",
        color: "success",
        icon: "icons/duotune/art/art005.svg",
        title: "當天產能負荷量",
        text: "數值",
        number: "9,231",
      },
      {
        type: "智慧排程",
        color: "danger",
        icon: "icons/duotune/communication/com012.svg",
        title: "當天稼動率",
        text: "數值",
        number: "72.57%",
      },
      {
        type: "智慧排程",
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "當月平均達交率",
        text: "數值",
        number: "100.00",
      },
      {
        type: "SPC自動監控",
        color: "warning",
        icon: "icons/duotune/abstract/abs027.svg",
        title: "累計檢測數量",
        text: "數值",
        number: "2,200",
      },
      {
        type: "SPC自動監控",
        color: "success",
        icon: "icons/duotune/art/art005.svg",
        title: "最近一次警示",
        text: "警示時間",
        number: "2022/09/14",
      },
      {
        type: "SPC自動監控",
        color: "danger",
        icon: "icons/duotune/communication/com012.svg",
        title: "當天平均CPK",
        text: "數值",
        number: "1.26",
      },
      {
        type: "SPC自動監控",
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "當天平均不良率",
        text: "數值",
        number: "0.00",
      },
      {
        type: "熱處理最佳參數",
        color: "warning",
        icon: "icons/duotune/abstract/abs027.svg",
        title: "累計模擬次數",
        text: "數值",
        number: "310",
      },
      {
        type: "熱處理最佳參數",
        color: "success",
        icon: "icons/duotune/art/art005.svg",
        title: "當月模擬次數",
        text: "警示時間",
        number: "2022/09/15",
      },
      {
        type: "熱處理最佳參數",
        color: "danger",
        icon: "icons/duotune/communication/com012.svg",
        title: "每月模擬次數平均值",
        text: "數值",
        number: "5.0",
      },
      {
        type: "熱處理最佳參數",
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "最多模擬料號",
        text: "文字",
        number: "RT642-065-00",
      },
      {
        type: "熱處理技術",
        color: "warning",
        icon: "icons/duotune/abstract/abs027.svg",
        title: "最近一次模擬時間",
        text: "時間",
        number: "2022/09/02",
      },
      {
        type: "熱處理技術",
        color: "success",
        icon: "icons/duotune/art/art005.svg",
        title: "最近一次模擬料號",
        text: "料號",
        number: "ANT-181313-00",
      },
      {
        type: "熱處理技術",
        color: "danger",
        icon: "icons/duotune/communication/com012.svg",
        title: "當月模擬次數",
        text: "數值",
        number: "2",
      },
      {
        type: "熱處理技術",
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "累計模擬次數",
        text: "數值",
        number: "7",
      },
      {
        type: "生產履歷",
        color: "warning",
        icon: "icons/duotune/abstract/abs027.svg",
        title: "最近一次查詢",
        text: "查詢時間",
        number: "2022/09/30",
      },
      {
        type: "生產履歷",
        color: "success",
        icon: "icons/duotune/art/art005.svg",
        title: "當月查詢次數",
        text: "警示時間",
        number: "26",
      },
      {
        type: "生產履歷",
        color: "danger",
        icon: "icons/duotune/communication/com012.svg",
        title: "累計查詢次數",
        text: "數值",
        number: "53",
      },
      {
        type: "生產履歷",
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "最近一次查詢客戶",
        text: "客戶名稱",
        number: "臻裕",
      },
    ]);

    return {
      list,
    };
  },
});
